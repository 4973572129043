/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import Layout from "../components/layout";
import Container from "../components/container";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import ArticleRelatedContent from "../components/entities/article/articleRelatedContent";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
const Article = ({ data }) => {
  const article = data.sanityArticle;
  let articlePath = "https://allstarcnc.com/blog/" + article.slug.current;
  let bgImage = convertToBgImage(
    data.heroImage.childImageSharp.gatsbyImageData
  );
  if (article.main_image) {
    bgImage = convertToBgImage(article.main_image.asset.gatsbyImageData);
  }

  return (
    <Layout type="story-outer">
      <SEO
        title={article.headline}
        lang="en"
        description={article._rawIntroText}
      ></SEO>
      <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
        <Container>
          <div
            sx={{
              position: "relative",
              zIndex: "2",
              padding: "180px 0px 100px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 sx={{ color: "white" }}>Stories</h1>
          </div>
        </Container>
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.7",
            background:
              "linear-gradient(to top, transparent 0%,#000000 75%, #000000 100%)",
          }}
        ></div>
      </BackgroundImage>
      <Container>
        <div
          sx={{
            margin: ["50px 0px", "100px 0px"],
          }}
        >
          {article.headline && (
            <h1
              id="article-title"
              sx={{
                fontSize: ["2.75rem", "3rem"],
                margin: "0px 0px 10px 0px",
                lineHeight: ["2.875rem", "3.4rem"],
              }}
            >
              {article.headline}
            </h1>
          )}
          {article._rawIntroText && (
            <div
              sx={{
                margin: "0px",
                color: "textGray",
                fontSize: "1.4rem",
                lineHeight: "2.1rem",
                a: {
                  color: "textGray",
                },
                p: {
                  margin: "0px",
                  fontSize: "1.4rem",
                  lineHeight: "2.1rem",
                },
              }}
            >
              <PortableText
                value={article._rawIntroText}
                components={PortableTextComponent}
              />
            </div>
          )}
          <div
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "40px",
              flexWrap: "wrap",
            }}
          >
            <div
              sx={{
                width: ["100%", "100%", "calc(70% - 50px)"],
              }}
            >
              {article.main_image && (
                <div className="article-full-main-image">
                  <GatsbyImage
                    sx={{
                      borderRadius: "8px",
                    }}
                    image={article.main_image.asset.gatsbyImageData}
                  />
                </div>
              )}
              {article._rawBody && (
                <div
                  sx={{
                    margin: "35px 0px 0px 0px",
                    fontSize: "body",
                    color: "textGray",
                    "h1,h2,h3,h4,h5,h6": {
                      color: "primary",
                      margin: "2rem 0px .7rem 0px",
                    },
                    a: {
                      color: "textGray",
                    },
                    p: {
                      lineHeight: "2rem",
                      margin: ".7rem 0rem",
                    },
                    ul: {
                      margin: "0rem",
                    },
                    li: {
                      lineHeight: "2.2rem",
                    },
                    ".gatsby-image-wrapper": {
                      margin: "2.5rem 0rem",
                      display: "inline-block",
                      maxWidth: "100%",
                      borderRadius: "8px",
                      "> div": {
                        maxWidth: "100% !important",
                      },
                    },
                  }}
                >
                  <PortableText
                    value={article._rawBody}
                    components={PortableTextComponent}
                  />
                </div>
              )}
              {article._rawAuthors && (
                <div
                  className="article-author"
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "1rem",
                    fontFamily: "primary",
                    marginTop: "2rem",
                  }}
                >
                  WRITTEN BY {article._rawAuthors[0].name}
                </div>
              )}
              {article._rawTopics && (
                <div
                  sx={{
                    marginTop: "2.625rem",
                  }}
                >
                  <div
                    sx={{
                      fontFamily: "primary",
                      fontSize: "1.5rem",
                    }}
                  >
                    Topics
                  </div>
                  <ul
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "0rem",
                      marginBottom: "0px",
                    }}
                  >
                    {article._rawTopics.map(
                      (tag, index) =>
                        tag.slug && (
                          <li
                            sx={{
                              listStyle: "none",
                              marginRight: "0.75rem",
                              marginBottom: "0.75rem",
                            }}
                            key={index}
                          >
                            <Link
                              sx={{
                                variant: "buttons.primaryWhite",
                              }}
                              to={"/" + tag.slug.current}
                            >
                              {tag.name}
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              )}
            </div>
            <div
              className="ep-quiz-widget"
              sx={{
                width: ["100%", "100%", "30%"],
                marginBottom: "0.75rem",
                height: "100%",
                position: ["relative", "sticky"],
                top: "40px",
                h1: {
                  fontSize: "2em",
                },
              }}
            >
              {/* The quiz widget is injected here */}
            </div>
          </div>
        </div>
      </Container>
      <div>
        <Container>
          {data.relatedContent && (
            <div
              sx={{
                padding: "0px 0px 80px 0px",
              }}
            >
              <div
                sx={{
                  fontSize: "3rem",
                  marginBottom: "35px",
                }}
              >
                Related Content
              </div>
              <ul
                sx={{
                  padding: "0px",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {data.relatedContent.nodes.map((relatedContent, index) => (
                  <li
                    key={index}
                    sx={{
                      listStyle: "none",
                      width: ["100%", "100%", "calc(50% - 28px)"],
                      marginBottom: "64px",
                    }}
                  >
                    <ArticleRelatedContent article={relatedContent} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Container>
      </div>
    </Layout>
  );
};
export default Article;

export const ArticleQuery = graphql`
  query ArticleBySlug($slug: String!) {
    facebookIcon: file(name: { eq: "Facebook" }) {
      publicURL
    }
    twitterIcon: file(name: { eq: "Twitter" }) {
      publicURL
    }
    emailIcon: file(name: { eq: "Email" }) {
      publicURL
    }
    heroImage: file(name: { eq: "freedom" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sanityArticle(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      publish_date(formatString: "MMMM D, Y")
      headline
      main_image {
        asset {
          gatsbyImageData(width: 1500)
        }
      }
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawAuthors(resolveReferences: { maxDepth: 10 })
      _rawCategory(resolveReferences: { maxDepth: 10 })
      _rawTopics(resolveReferences: { maxDepth: 10 })
    }
  }
`;
